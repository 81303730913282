import { Link } from 'gatsby';
import React from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { FiCheck, FiTrash2 } from 'react-icons/fi';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import IconText from 'components/IconText/IconText';
import DeliveryTruckStatic from 'components/Icons/Project/DeliveryTruckStatic';
import Image from 'components/Image';
import QuantityButton from 'components/QuantityButton/QuantityButton';

import { useCart } from 'hooks/useCart';

import { subtractPercentage } from 'utilities/percentage';
import { isUnavailable, isLessAvailable } from 'utilities/product';

import * as styles from './Cart.module.scss';

const errorStyle = { marginTop: '0.5rem' };

const Cart = ({ quantities, submitCoupon, coupon }) => {
  const { register, handleSubmit } = useForm();
  const { cart: initialCart, totals, removeItem, adjustItem } = useCart();
  const freeTransfer =
    totals.priceWithoutFee && totals.priceWithoutFee.split('.')[0];
  const cart = initialCart.map((item) => {
    let unavailable = isUnavailable(item.id, quantities);
    let lessAvailable = isLessAvailable(item.id, item.quantity, quantities);
    return {
      ...item,
      isUnavailable: unavailable,
      isLessAvailable: lessAvailable,
      isAvailable: !unavailable && !lessAvailable,
    };
  });

  const onSubmit = (data) => submitCoupon(data.coupon);

  const calcTotals = (total) =>
    total +
    (totals.hasCashShippingFee ? totals.cashShippingFeeInt : 0) +
    (totals.hasShippingFee ? totals.shippingFeeInt : 0);

  return (
    <ul>
      {cart && cart.length > 0 ? (
        <>
          {cart.map((item) => (
            <li
              key={item.id}
              className={`${styles.item} ${
                isUnavailable(item.id, quantities) && styles.itemError
              }`}
            >
              <Link to={item.relativePath} className={styles.link}>
                <figure className={styles.figure}>
                  <Image
                    filename={item.product.images[0].hash}
                    alt={
                      item.product.images[0].alternativeText ||
                      item.product.title
                    }
                    styles={{ display: 'block' }}
                  />
                </figure>
              </Link>
              <div className={styles.contentContainer}>
                <Link to={item.relativePath} className={styles.link}>
                  <div className={styles.content}>
                    <h4 className={styles.title}>{item.product.title}</h4>
                    <p className={styles.price}>
                      {((item.price * item.quantity) / 100).toFixed(2)} €
                    </p>
                  </div>
                </Link>
                {item.isAvailable && (
                  <h4 className={styles.available}>
                    <IconContext.Provider value={{ size: '16px' }}>
                      <FiCheck style={{ marginRight: '2px' }} />
                    </IconContext.Provider>
                    ΑΜΕΣΑ ΔΙΑΘΕΣΙΜΟ
                  </h4>
                )}
                {item.isUnavailable && (
                  <ErrorMessage style={errorStyle}>
                    Λυπούμαστε αλλά το προϊόν δεν είναι πλέον διαθέσιμο!
                  </ErrorMessage>
                )}
                {item.isLessAvailable && (
                  <ErrorMessage style={errorStyle}>
                    {`Περιορισμένη διαθεσιμότητα! Μόνο ${
                      quantities[item.id] && quantities[item.id].quantity === 1
                        ? '1 προϊόν διαθέσιμο'
                        : `${
                            quantities[item.id] && quantities[item.id].quantity
                          } προϊόντα διαθέσιμα`
                    }.`}
                  </ErrorMessage>
                )}
                <div className={styles.actionsContainer}>
                  <QuantityButton
                    quantity={item.quantity}
                    add={() => {
                      item.quantity < 9 &&
                        adjustItem(item.id, item.quantity + 1);
                    }}
                    subtract={() => {
                      item.quantity > 1 &&
                        adjustItem(item.id, item.quantity - 1);
                    }}
                  />
                  <button
                    type="button"
                    className={styles.closeBtn}
                    onClick={(ev) => {
                      ev.preventDefault();
                      removeItem(item);
                    }}
                  >
                    <IconContext.Provider
                      value={{ color: '#b1b1af', size: '1.125rem' }}
                    >
                      <FiTrash2 />
                    </IconContext.Provider>
                  </button>
                </div>
              </div>
            </li>
          ))}
          <div className={styles.couponContainer}>
            <h4>Έχετε εκπτωτικό κουπόνι;</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  name="coupon"
                  data-testid="coupon"
                  placeholder="Εισάγετε τον κωδικό κουπονιού"
                  className={styles.input}
                  ref={register({ required: true })}
                />
                <button
                  data-testid="coupon-submit"
                  disabled={coupon.loading}
                  className={styles.btn}
                  type="submit"
                >
                  ΕΦΑΡΜΟΓΗ ΕΚΠΤΩΣΗΣ
                </button>
              </div>
              {coupon.error && (
                <ErrorMessage style={errorStyle}>
                  Δεν βρέθηκε έγκυρο κουπόνι.
                </ErrorMessage>
              )}
              {coupon.data && coupon.data.code ? (
                <p className={`${styles.successMsg}`}>
                  {`Το κουπόνι βρέθηκε. Θα λάβετε έκπτωση ${
                    coupon.data.offerValue
                  }${
                    coupon.data.offerType === 'percentage' ? '%' : '€'
                  } στην αξία των προϊόντων.`}
                </p>
              ) : null}
            </form>
          </div>
          {totals && totals.sum && (
            <div className={styles.sumContainer}>
              <div className={styles.iconContainer}>
                <IconText
                  title="ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ"
                  icon={<DeliveryTruckStatic />}
                  text={`για αγορές άνω των ${freeTransfer || '99'}€`}
                  iconSize="large"
                  style={{ margin: '1rem 0', marginLeft: '-1rem' }}
                  titleStyle={{ marginBottom: '0.5rem' }}
                  textStyle={{ marginBottom: '0' }}
                  iconStyle={{ margin: '0', marginRight: '1rem' }}
                />
              </div>
              <div className={styles.separator}></div>
              <div className={styles.sumInnerContainer}>
                <h6 className={styles.pricingContainer}>
                  <span className={styles.pricingText}>Αξία προϊόντων</span>{' '}
                  <span className={styles.pricingAmount}>
                    {totals.amount} €
                  </span>
                </h6>
                {coupon.data && coupon.data.code ? (
                  <h6 className={styles.pricingContainer}>
                    <span className={styles.pricingText}>
                      Έκπτωση κουπονιού
                    </span>{' '}
                    <span className={styles.pricingAmount}>
                      -
                      {`${coupon.data.offerValue} ${
                        coupon.data.offerType === 'percentage' ? '%' : '€'
                      }`}
                    </span>
                  </h6>
                ) : null}
                {totals.hasCashShippingFee && (
                  <h6 className={styles.pricingContainer}>
                    <span className={styles.pricingText}>
                      Κόστος Αντικαταβολής
                    </span>{' '}
                    <span className={styles.pricingAmount}>
                      +{totals.cashShippingFee} €
                    </span>
                  </h6>
                )}
                <h6 className={styles.pricingContainer}>
                  <span className={styles.pricingText}>Μεταφορικά</span>{' '}
                  <span className={styles.pricingAmount}>
                    +{totals.hasShippingFee ? totals.shippingFee : '0.00'} €
                  </span>
                </h6>
                <hr style={{ marginTop: '1rem' }} />
                <h6 className={`${styles.pricingContainer} ${styles.sum}`}>
                  <span className={styles.pricingText}>ΣΥΝΟΛΟ</span>{' '}
                  {coupon.data && coupon.data.offerType ? (
                    coupon.data.offerType === 'percentage' ? (
                      <span className={styles.pricingAmount}>
                        {(
                          calcTotals(
                            subtractPercentage(
                              totals.amountInt,
                              coupon.data.offerValue
                            )
                          ) / 100
                        ).toFixed(2)}{' '}
                        €
                      </span>
                    ) : (
                      <span className={styles.pricingAmount}>
                        {(
                          calcTotals(
                            totals.amountInt - coupon.data.offerValue
                          ) / 100
                        ).toFixed(2)}{' '}
                        €
                      </span>
                    )
                  ) : (
                    <span className={styles.pricingAmount}>{totals.sum} €</span>
                  )}
                </h6>
              </div>
            </div>
          )}
        </>
      ) : (
        <li className={styles.empty}>Το καλάθι είναι άδειο.</li>
      )}
    </ul>
  );
};

export default Cart;
