// extracted by mini-css-extract-plugin
export var form = "CheckoutForm-module--form--2Zxco";
export var row = "CheckoutForm-module--row--2QBU4";
export var title = "CheckoutForm-module--title--QI2QI";
export var inputContainer = "CheckoutForm-module--inputContainer--XQmMa";
export var input = "CheckoutForm-module--input--1YbZ4";
export var select = "CheckoutForm-module--select--3rezt";
export var addressSelect = "CheckoutForm-module--addressSelect--1XBIq";
export var primaryText = "CheckoutForm-module--primaryText--3DSo-";
export var secondaryText = "CheckoutForm-module--secondaryText--2uiFV";
export var selectAddressText = "CheckoutForm-module--selectAddressText--2zJD4";
export var label = "CheckoutForm-module--label--B4h2H";
export var area = "CheckoutForm-module--area--CDt-s";
export var checkboxContainer = "CheckoutForm-module--checkboxContainer--1uAyp";