import { useState, useEffect } from 'react';

import CartStore from 'store/cart';

export const useCash = () => {
  const [isCash, setCash] = useState(null);

  useEffect(() => {
    const subscription = CartStore.isCashSubscribe(setCash);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const setIsCash = (state) => {
    CartStore.setIsCash(state);
  };

  return { isCash, setIsCash };
};

export default useCash;
