const isUnavailable = (id, quantities) =>
  quantities &&
  (quantities[id]?.quantity === 0 || quantities[id]?.enabled === false);

const isLessAvailable = (id, quantity, quantities) =>
  quantities &&
  quantities[id]?.quantity > 0 &&
  quantities[id]?.quantity < quantity;

export { isUnavailable, isLessAvailable };
