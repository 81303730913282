// extracted by mini-css-extract-plugin
export var fullHeightContainer = "checkout-module--fullHeightContainer--1bCl1";
export var header = "checkout-module--header--3dhid";
export var brand = "checkout-module--brand--8qwe9";
export var title = "checkout-module--title--vHe3L";
export var container = "checkout-module--container--3b2kI";
export var leftContainer = "checkout-module--left-container--3IOfa";
export var rightContainer = "checkout-module--right-container--1emq_";
export var simpleContainer = "checkout-module--simpleContainer--2cKr4";
export var titleContainer = "checkout-module--titleContainer--3AtDn";
export var signupContainer = "checkout-module--signupContainer--lXNwI";
export var signupTitle = "checkout-module--signupTitle--HsJkB";
export var formContainer = "checkout-module--formContainer--1EQMJ";
export var error = "checkout-module--error--2GQJU checkout-module--simpleContainer--2cKr4";
export var infoText = "checkout-module--infoText--Ne54e";
export var btnCheckout = "checkout-module--btn-checkout--1g8Y6";